// Función para capitalizar las palabras
const capitalizeWords = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export async function fetchRestaurants(foodType, postalCode, country) {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const endpoint = "https://places.googleapis.com/v1/places:searchText";

  const body = {
    textQuery: `${foodType} en el codigo postal ${postalCode} de ${country}`,
  };

  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": apiKey,
        "X-Goog-FieldMask":
          "places.displayName,places.shortFormattedAddress,places.googleMapsLinks,places.rating",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    // Formatear resultados
    if (data.places && data.places.length > 2) {
      // Ordenar los lugares por calificación de mayor a menor
      const sortedPlaces = data.places.sort((a, b) => b.rating - a.rating);
    
      // Limitar a los primeros 10 resultados después de ordenar
      const limitedPlaces = sortedPlaces.slice(0, 10);
    
      // Formatear los resultados
      return limitedPlaces.map((place) => ({
        name: capitalizeWords(place.displayName.text), // Normalización del nombre
        address: place.shortFormattedAddress, // Dirección del lugar
        link: place.googleMapsLinks.placeUri, // Enlace de Google Maps
        rating: place.rating // Calificación
      }));
    }    

    return [];
  } catch (error) {
    console.error("Error fetching restaurants:", error);
    return [];
  }
}
