import React, { useState } from "react";
import Select from "react-select";

interface PopupProps {
  onSubmit: (postalCode: string, country: string) => void;
  onClose: () => void;
}

const MobilePopup: React.FC<PopupProps> = ({ onSubmit, onClose }) => {
  const [postalCode, setPostalCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("Argentina");

  const countries = [
    { label: "Argentina", value: "Argentina", flag: "/images/argentina.png" },
    { label: "Chile", value: "Chile", flag: "/images/chile.png" },
    { label: "Bolivia", value: "Bolivia", flag: "/images/bolivia.png" },
    { label: "Brasil", value: "Brasil", flag: "/images/brazil.png" },
    { label: "Colombia", value: "Colombia", flag: "/images/colombia.png" },
    { label: "Ecuador", value: "Ecuador", flag: "/images/ecuador.png" },
    { label: "Mexico", value: "Mexico", flag: "/images/mexico.png" },
    { label: "Paraguay", value: "Paraguay", flag: "/images/paraguay.png" },
    { label: "Peru", value: "Peru", flag: "/images/peru.png" },
    { label: "Uruguay", value: "Uruguay", flag: "/images/uruguay.png" },
    { label: "Venezuela", value: "Venezuela", flag: "/images/venezuela.png" },
  ];

  const handleSubmit = () => {
    if (postalCode.trim()) {
      onSubmit(postalCode, selectedCountry);
    } else {
      alert("Por favor, ingresa un código postal válido.");
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 p-4"
      role="dialog"
      aria-labelledby="popup-title"
      aria-modal="true"
    >
      <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 id="popup-title" className="text-xl font-bold">
            ¡Te queremos recomendar restaurantes!
          </h2>
          <button
            onClick={onClose}
            className="bg-red-500 text-white w-8 h-8 rounded-md flex items-center justify-center text-lg font-bold hover:bg-red-600"
            aria-label="Cerrar"
          >
            X
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label htmlFor="country-select" className="block text-gray-700 mb-2">
              Selecciona tu país:
            </label>
            <Select
              id="country-select"
              defaultValue={countries.find((c) => c.value === selectedCountry)}
              options={countries}
              formatOptionLabel={(e) => (
                <div className="flex items-center">
                  <img
                    src={e.flag}
                    alt={`${e.label} flag`}
                    className="w-6 h-4 mr-2"
                  />
                  {e.label}
                </div>
              )}
              onChange={(selectedOption) =>
                setSelectedCountry(selectedOption?.value || "")
              }
              className="w-full"
            />
          </div>

          <div>
            <label htmlFor="postal-code" className="block text-gray-700 mb-2">
              Ingresa tu CP:
            </label>
            <input
              id="postal-code"
              type="text"
              placeholder="Código Postal"
              value={postalCode}
              onChange={(e) => {
                if (e.target.value.length <= 4) {
                  setPostalCode(e.target.value);
                }
              }}
              maxLength={4}
              className="w-full border border-gray-300 rounded px-3 py-2 text-lg"
            />
          </div>
        </div>

        <div className="mt-6">
          <button
            onClick={handleSubmit}
            className="w-full bg-blue-500 text-white py-3 rounded text-lg font-semibold hover:bg-blue-600"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobilePopup;

